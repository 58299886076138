import React from "react";
import "./styles/_projects.scss";
import "./styles/_forms.scss";

function App() {
    return (
        <div className="index">
            <h1>Index</h1>
        </div>
    );
}

export default App;
