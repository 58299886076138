import React, { Component } from "react";
import dateFormat from "dateformat";

import Session from "../Session";
import Modal from "./Modal";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: "index",
            pageId: props.page.match.params.hash,
            pageName: "",
            projectID: this.props.page.match.params.hash,
            id: null,
            validUser: false,
        };

        this.changeState = this.changeState.bind(this);
        this.props.renderSecondaryNav();
        this.checkAuth(Session.getSession(), this.state.pageId);
    }

    checkAuth(userToken, pageId) {
        fetch(`${Session.GLOBAL.serverURL}/projects/campaign/auth`, {
            method: "POST",
            body: JSON.stringify({ userToken, pageId }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    this.setState({
                        validUser: true,
                        pageName: results[0].name,
                    });
                } else {
                    console.warn("Invalid user");
                    window.location.href = "/404";
                }
            });
    }

    changeState(value, id = null) {
        this.setState({ page: value, id: id });
    }

    render() {
        return (
            <div className="projects list">
                <div className="top">
                    <h1>{this.state.pageName} Segments</h1>
                    {this.state.page === "index" ? (
                        <div>
                            <button
                                className="link"
                                onClick={() => this.changeState("create")}
                            >
                                Create A Segment
                            </button>
                        </div>
                    ) : (
                        <button
                            className="link"
                            onClick={() => this.changeState("index")}
                        >
                            Back To Segments
                        </button>
                    )}
                </div>

                {(() => {
                    switch (this.state.page) {
                        case "create":
                            return (
                                <SegmentCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        case "edit":
                            return (
                                <SegmentCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        default:
                            return (
                                <SegmentList
                                    parentCallback={this.changeState}
                                    projectID={this.state.projectID}
                                />
                            );
                    }
                })()}
            </div>
        );
    }
}

class SegmentList extends Component {
    constructor(props) {
        super(props);
        this.items = [];

        this.parentCallback = this.parentCallback.bind(this);
        this.getSegments = this.getSegments.bind(this);
        this.getSegments();
    }

    parentCallback(id) {
        this.props.parentCallback("edit", id);
    }

    getSegments() {
        const user_token = Session.getSession();
        const project = this.props.projectID;

        fetch(`${Session.GLOBAL.serverURL}/project/segments/get`, {
            method: "POST",
            body: JSON.stringify({ user_token, project }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    for (const [index, value] of results.entries()) {
                        this.items.push(
                            <li
                                key={index}
                                id={value.id}
                                onClick={() => this.parentCallback(value.id)}
                            >
                                <span>{value.name}</span>
                                <span>
                                    {dateFormat(
                                        value.updated_at,
                                        "mmm dd yyyy hh:MM:ss tt"
                                    )}
                                </span>
                            </li>
                        );
                    }
                } else {
                    this.items.push("No segment created");
                }
                this.forceUpdate();
            });
    }

    render() {
        return (
            <div className="project-list">
                <ul>{this.items.length > 0 ? this.items : "Loading..."}</ul>
            </div>
        );
    }
}

class SegmentCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            user_token: Session.getSession(),
            validSegment: false,
            errorMsg: "",
            id: this.props.state.id,
            project: this.props.projectID,
            modal: false,
        };

        this.validateSegment = this.validateSegment.bind(this);
        this.saveSegment = this.saveSegment.bind(this);
        this.parentCallback = this.parentCallback.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.deleteSegment = this.deleteSegment.bind(this);

        if (this.props.state.id) {
            this.pullData();
        }
    }

    validateSegment() {
        if (this.state.name.length > 0) {
            this.setState({ validSegment: true });
        } else {
            this.setState({ validSegment: false });
        }
    }

    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value }, function () {
            this.validateSegment();
        });
    };

    saveSegment(e) {
        e.preventDefault();
        this.setState({ errorMsg: "" });

        if (this.state.validSegment) {
            fetch(`${Session.GLOBAL.serverURL}/project/segment/save`, {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((results) => {
                    if (results.error) {
                        this.setState({ errorMsg: results.msg });
                        console.warn("denied");
                    } else {
                        this.parentCallback();
                    }
                });
        }
    }

    deleteSegment() {
        fetch(`${Session.GLOBAL.serverURL}/project/segment/delete`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else {
                    this.parentCallback();
                }
            });
    }

    parentCallback() {
        this.props.parentCallback("index");
    }

    toggleModal() {
        let active = !this.state.modal;
        this.setState({ modal: active });
    }

    confirmModal() {
        this.deleteSegment();
    }

    pullData() {
        fetch(`${Session.GLOBAL.serverURL}/project/segment/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else if (results.length === 0) {
                    this.setState({
                        errorMsg: "Cannot pull segment information",
                    });
                    console.warn("error");
                } else {
                    let data = results[0];
                    this.setState({
                        name: data.name,
                    });

                    this.validateSegment();
                }
            });
    }

    render() {
        return (
            <div className="create-form">
                {this.state.id ? (
                    <h2>Edit Segment</h2>
                ) : (
                    <h2>Create New Segment</h2>
                )}
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}
                <form>
                    <label htmlFor="name">
                        <span>Name: </span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="name"
                            value={this.state.name}
                        />
                    </label>
                    <input type="hidden" value={this.state.project} />
                    <div className="form-buttons">
                        <button
                            type="submit"
                            onClick={this.saveSegment}
                            disabled={!this.state.validSegment}
                        >
                            Save
                        </button>
                        {this.state.id && (
                            <button
                                type="button"
                                className="delete"
                                onClick={this.toggleModal}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </form>

                {this.state.modal && (
                    <Modal
                        msg="Are you sure you want to delete this segment?"
                        toggle={this.toggleModal}
                        confirm={this.confirmModal}
                    />
                )}
            </div>
        );
    }
}

export default App;
