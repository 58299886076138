import React, { Component } from "react";
import { CirclePicker } from "react-color";
import dateFormat from "dateformat";

import Session from "../Session";
import Modal from "./Modal";
import "./styles/_projects.scss";

class App extends Component {
    constructor() {
        super();

        this.state = {
            page: "index",
            id: null
        };

        this.changeState = this.changeState.bind(this);
        this.updateHeader = this.updateHeader.bind(this);
    }

    changeState(value, id = null) {
        this.setState({ page: value, id: id });
    }

    updateHeader() {
        this.props.update();
    }

    render() {
        return (
            <div className="projects">
                <div className="top">
                    <h1>Projects</h1>
                    {this.state.page === "index" ? (
                        <button
                            className="link"
                            onClick={() => this.changeState("create")}
                        >
                            Create A Project
                        </button>
                    ) : (
                        <button
                            className="link"
                            onClick={() => this.changeState("index")}
                        >
                            Back To Projects
                        </button>
                    )}
                </div>

                {(() => {
                    switch (this.state.page) {
                        case "create":
                            return (
                                <ProjectCreate
                                    parentCallback={this.changeState}
                                    updateHeader={this.updateHeader}
                                    state={this.state}
                                />
                            );
                        case "edit":
                            return (
                                <ProjectCreate
                                    parentCallback={this.changeState}
                                    updateHeader={this.updateHeader}
                                    state={this.state}
                                />
                            );
                        default:
                            return (
                                <ProjectList
                                    parentCallback={this.changeState}
                                />
                            );
                    }
                })()}
            </div>
        );
    }
}

class ProjectList extends Component {
    constructor() {
        super();
        this.items = [];

        this.parentCallback = this.parentCallback.bind(this);
        this.getProjects();
    }

    parentCallback(id) {
        this.props.parentCallback("edit", id);
    }

    getProjects() {
        const userToken = Session.getSession();
        fetch(`${Session.GLOBAL.serverURL}/projects/get`, {
            method: "POST",
            body: JSON.stringify({ userToken }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json(this.token))
            .then(results => {
                if (results.length > 0) {
                    for (const [index, value] of results.entries()) {
                        this.items.push(
                            <li key={index} id={value.id}>
                                <a
                                    href={"/project/" + value.id}
                                    className="entry"
                                >
                                    <span>{value.name}</span>
                                    <span>
                                        {dateFormat(
                                            value.updated_at,
                                            "mmm dd yyyy hh:MM:ss tt"
                                        )}
                                    </span>
                                </a>
                                <button
                                    onClick={() =>
                                        this.parentCallback(value.id)
                                    }
                                >
                                    Edit
                                </button>
                            </li>
                        );
                    }
                } else {
                    this.items.push("No project created");
                }
                this.forceUpdate();
            });
    }

    render() {
        return (
            <div className="project-list">
                <ul>{this.items.length > 0 ? this.items : "Loading..."}</ul>
            </div>
        );
    }
}

class ProjectCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project_name: "",
            email: "",
            email_name: "",
            color: "",
            user_token: Session.getSession(),
            validProject: false,
            errorMsg: "",
            id: this.props.state.id,
            modal: false
        };

        this.validateProject = this.validateProject.bind(this);
        this.saveProject = this.saveProject.bind(this);
        this.parentCallback = this.parentCallback.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.deleteProject = this.deleteProject.bind(this);

        if (this.props.state.id) {
            this.pullData();
        }
    }

    validateProject() {
        if (
            this.state.project_name.length > 0 &&
            this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
            this.state.email_name.length > 0 &&
            this.state.color.length > 0
        ) {
            this.setState({ validProject: true });
        } else {
            this.setState({ validProject: false });
        }
    }

    handleChangeInput = event => {
        this.setState({ [event.target.name]: event.target.value }, function() {
            this.validateProject();
        });
    };

    handleChangeComplete = (color, event) => {
        this.setState({ color: color.hex }, function() {
            this.validateProject();
        });
    };

    saveProject(e) {
        e.preventDefault();
        this.setState({ errorMsg: "" });

        if (this.state.validProject) {
            fetch(`${Session.GLOBAL.serverURL}/project/save`, {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(results => {
                    if (results.error) {
                        this.setState({ errorMsg: results.msg });
                        console.warn("denied");
                    } else {
                        this.parentCallback();
                    }
                });
        }
    }

    deleteProject() {
        fetch(`${Session.GLOBAL.serverURL}/project/delete`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(results => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else {
                    this.parentCallback();
                }
            });
    }

    parentCallback() {
        this.props.parentCallback("index");
        this.props.updateHeader();
    }

    toggleModal() {
        let active = !this.state.modal;
        this.setState({ modal: active });
    }

    confirmModal() {
        this.deleteProject();
    }

    pullData() {
        fetch(`${Session.GLOBAL.serverURL}/project/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(results => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else if (results.length === 0) {
                    this.setState({
                        errorMsg: "Cannot pull project information"
                    });
                    console.warn("error");
                } else {
                    let data = results[0];
                    this.setState({
                        project_name: data.name,
                        email: data.email,
                        email_name: data.email_name,
                        color: data.color
                    });

                    this.validateProject();
                }
            });
    }

    render() {
        return (
            <div className="create-form">
                {this.state.id ? (
                    <h2>Edit Project</h2>
                ) : (
                    <h2>Create New Project</h2>
                )}
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}
                <form>
                    <label htmlFor="project_name">
                        <span>Project Name:</span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="project_name"
                            value={this.state.project_name}
                            disabled={this.state.id ? true : undefined}
                        />
                    </label>
                    <label htmlFor="email">
                        <span>Email From Address:</span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="email"
                            value={this.state.email}
                        />
                    </label>
                    <label htmlFor="email_name">
                        <span>Email From Name:</span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="email_name"
                            value={this.state.email_name}
                        />
                    </label>
                    <p>
                        <em>
                            Specify SPF record for your sending domain, type
                            TXT:
                        </em>
                        <input
                            type="text"
                            disabled={true}
                            name="spf1_record"
                            value="v=spf1 include:host.sparks-media.com +a +mx ~all"
                        />
                    </p>

                    <label className="color-picker" htmlFor="color_picker">
                        <span>Project Color:</span>
                        <CirclePicker
                            onChangeComplete={this.handleChangeComplete}
                            color={this.state.color}
                        />
                    </label>
                    <div className="form-buttons">
                        <button
                            type="submit"
                            onClick={this.saveProject}
                            disabled={!this.state.validProject}
                        >
                            Save
                        </button>
                        {this.state.id && (
                            <button
                                type="button"
                                className="delete"
                                onClick={this.toggleModal}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </form>

                {this.state.modal && (
                    <Modal
                        msg="Are you sure you want to delete this project?"
                        toggle={this.toggleModal}
                        confirm={this.confirmModal}
                    />
                )}
            </div>
        );
    }
}

export default App;
