var UserProfile = (function() {
    const GLOBAL = {
        serverURL: process.env.REACT_APP_BACKEND,
        passwordLength: 4
    };

    var checkSession = function() {
        let user = localStorage.getItem("token") || "";
        let token = user.split("|")[1];
        console.log(`check session ${token}`);

        if (user.length > 0) {
            //CHECK SESSION TOKEN
            fetch(`${GLOBAL.serverURL}/auth`, {
                method: "POST",
                body: JSON.stringify({ token }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(results => {
                    if (!results) {
                        this.endSession();
                        window.location.href = "/login";
                    }
                });
        }

        return token;
    };

    var getSession = function() {
        let user = localStorage.getItem("token") || "";
        let token = user.split("|")[1];
        return token;
    };

    var startSession = function(name, email, token) {
        localStorage.setItem("token", `${name}|${token}`);
    };

    var endSession = function() {
        localStorage.setItem("token", "");
    };

    return {
        checkSession: checkSession,
        getSession: getSession,
        startSession: startSession,
        endSession: endSession,
        GLOBAL: GLOBAL
    };
})();

export default UserProfile;
