import React, { Component } from "react";
import Session from "../Session";

import SignIn from "./SignIn";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginToggle: false,
            projects: []
        };

        this.logIn = this.logIn.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
        this.getProjects();
    }

    getProjects() {
        const userToken = Session.getSession();
        fetch(`${Session.GLOBAL.serverURL}/projects/get`, {
            method: "POST",
            body: JSON.stringify({ userToken }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json(this.token))
            .then(results => {
                if (results.length > 0) {
                    let projects = [];

                    for (const [index, value] of results.entries()) {
                        projects.push(
                            <li key={index}>
                                <a
                                    href={"/project/" + value.id}
                                    className={
                                        window.location.pathname ===
                                        "/project/" + value.id
                                            ? "active"
                                            : ""
                                    }
                                >
                                    {value.name}
                                </a>
                            </li>
                        );
                    }

                    this.setState({ projects: projects });
                }
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.update !== this.props.update) {
            this.getProjects();
        }
    }

    logIn() {
        this.setState({ loginToggle: true });
    }

    logOut() {
        Session.endSession();
        window.location.href = "/";
    }

    callbackFunction(state) {
        this.setState({ loginToggle: state });
    }

    render() {
        return (
            <nav>
                <ul>
                    {this.props.loggedIn && (
                        <li>
                            <a
                                href="/projects"
                                className={
                                    window.location.pathname === "/projects"
                                        ? "active"
                                        : ""
                                }
                            >
                                Projects
                            </a>
                            {this.state.projects.length > 0 && (
                                <ul>{this.state.projects}</ul>
                            )}
                        </li>
                    )}

                    {this.props.loggedIn && (
                        <li>
                            <a
                                href="/account"
                                className={
                                    window.location.pathname === "/account"
                                        ? "active"
                                        : ""
                                }
                            >
                                My Account
                            </a>
                        </li>
                    )}

                    <li>
                        {this.props.loggedIn ? (
                            <button className="link" onClick={this.logOut}>
                                Sign Out
                            </button>
                        ) : (
                            <button className="link" onClick={this.logIn}>
                                Sign In
                            </button>
                        )}
                    </li>
                </ul>

                {this.state.loginToggle && (
                    <SignIn parentCallback={this.callbackFunction} />
                )}
            </nav>
        );
    }
}

export default App;
