import React, { Component } from "react";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Session from "../Session";
import Modal from "./Modal";
import "./styles/_emails.scss";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: "index",
            pageId: props.page.match.params.hash,
            pageName: "",
            projectID: this.props.page.match.params.hash,
            id: null,
            validUser: false,
        };

        this.changeState = this.changeState.bind(this);
        this.props.renderSecondaryNav();
        this.checkAuth(Session.getSession(), this.state.pageId);
    }

    checkAuth(userToken, pageId) {
        fetch(`${Session.GLOBAL.serverURL}/projects/campaign/auth`, {
            method: "POST",
            body: JSON.stringify({ userToken, pageId }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    this.setState({
                        validUser: true,
                        pageName: results[0].name,
                    });
                } else {
                    console.warn("Invalid user");
                    window.location.href = "/404";
                }
            });
    }

    changeState(value, id = null) {
        this.setState({ page: value, id: id });
    }

    render() {
        return (
            <div className="projects list">
                <div className="top">
                    <h1>{this.state.pageName} Emails</h1>
                    {this.state.page === "index" ? (
                        <button
                            className="link"
                            onClick={() => this.changeState("create")}
                        >
                            Create An Email
                        </button>
                    ) : (
                        <button
                            className="link"
                            onClick={() => this.changeState("index")}
                        >
                            Back To Emails
                        </button>
                    )}
                </div>

                {(() => {
                    switch (this.state.page) {
                        case "create":
                            return (
                                <EmailCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        case "edit":
                            return (
                                <EmailCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        case "sent":
                            return (
                                <EmailReport
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        default:
                            return (
                                <EmailList
                                    parentCallback={this.changeState}
                                    projectID={this.state.projectID}
                                />
                            );
                    }
                })()}
            </div>
        );
    }
}

class EmailList extends Component {
    constructor(props) {
        super(props);
        this.items = [];

        this.parentCallback = this.parentCallback.bind(this);
        this.getEmails = this.getEmails.bind(this);
        this.getEmails();
    }

    parentCallback(id) {
        this.props.parentCallback("edit", id);
    }

    reportCallback(id) {
        this.props.parentCallback("sent", id);
    }

    getEmails() {
        const userToken = Session.getSession();
        const projectID = this.props.projectID;
        fetch(`${Session.GLOBAL.serverURL}/project/emails/get`, {
            method: "POST",
            body: JSON.stringify({ userToken, projectID }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.length > 0) {
                    for (const [index, value] of results.entries()) {
                        this.items.push(
                            <li
                                key={index}
                                id={value.id}
                                onClick={() =>
                                    value.sent === 1
                                        ? this.reportCallback(value.id)
                                        : this.parentCallback(value.id)
                                }
                                className={
                                    !value.active
                                        ? "inactive"
                                        : value.sent === 1
                                        ? "sent"
                                        : ""
                                }
                            >
                                <span>{value.name}</span>
                                {value.sent === 1 ? (
                                    <span>{value.sends} Sent Emails</span>
                                ) : (
                                    <span>{value.num} Subscribers</span>
                                )}

                                <span>
                                    {dateFormat(
                                        value.send_time,
                                        "mmm dd yyyy hh:MM:ss tt"
                                    )}
                                </span>
                            </li>
                        );
                    }
                } else {
                    this.items.push("No email created");
                }
                this.forceUpdate();
            });
    }

    render() {
        return (
            <div className="project-list">
                <ul>{this.items.length > 0 ? this.items : "Loading..."}</ul>
            </div>
        );
    }
}

class EmailCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email_name: "",
            email_subject: "",
            send_time: new Date(),
            send_time_valid: false,
            segment: "",
            html_email: "",
            email_sent: 0,
            sent_time: "",
            email_active: 1,
            user_token: Session.getSession(),
            validEmail: false,
            errorMsg: "",
            sendTestMsg: "",
            id: this.props.state.id,
            project: this.props.projectID,
            modal: false,
            modalMsg: false,
            modalAction: "",
        };

        this.segment_list = [];
        this.email_variables = [];

        this.validateEmail = this.validateEmail.bind(this);
        this.saveEmail = this.saveEmail.bind(this);
        this.parentCallback = this.parentCallback.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
        this.previewEmail = this.previewEmail.bind(this);
        this.sendTest = this.sendTest.bind(this);

        if (this.props.state.id) {
            this.pullData();
        }

        this.getSegments();
        this.getEmailVariables();
    }

    validateEmail() {
        if (
            this.state.email_name.length > 0 &&
            this.state.email_subject.length > 0 &&
            this.state.send_time_valid === true &&
            this.state.segment.length > 0 &&
            this.state.html_email.length > 0
        ) {
            this.setState({ validEmail: true });
        } else {
            this.setState({ validEmail: false });
        }
    }

    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value }, function () {
            this.validateEmail();
        });
    };

    handleDateChange = (date) => {
        this.setState({
            send_time: dateFormat(date, "yyyy-mm-dd HH:MM"),
        });

        this.validateTime(date);
    };

    handleCheckBox = (event) => {
        if (event.target.checked) {
            this.setState({ email_active: 1 });
        } else {
            this.setState({ email_active: 0 });
        }
    };

    saveEmail(e) {
        e.preventDefault();
        this.setState({ errorMsg: "" });

        if (this.state.validEmail) {
            fetch(`${Session.GLOBAL.serverURL}/project/email/save`, {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((results) => {
                    if (results.error) {
                        this.setState({ errorMsg: results.msg });
                        console.warn("denied");
                    } else {
                        this.parentCallback();
                    }
                });
        }
    }

    previewEmail() {
        var width =
            window.screen.width - Math.round(window.screen.width * 0.25);
        var height =
            window.screen.height - Math.round(window.screen.height * 0.25);
        var top = window.screen.height / 2 - height / 2;
        var left = window.screen.width / 2 - width / 2;

        var win = window.open(
            "",
            "Title",
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left +
                ""
        );
        win.document.body.innerHTML = this.state.html_email;
    }

    sendTest() {
        this.setState({ sendTestMsg: "", errorMsg: "" });
        fetch(`${Session.GLOBAL.serverURL}/project/email/test`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (!results.status) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else {
                    this.setState({ sendTestMsg: results.msg });
                    console.log(results);
                }
            });
    }

    deleteEmail() {
        fetch(`${Session.GLOBAL.serverURL}/project/email/delete`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else {
                    this.parentCallback();
                }
            });
    }

    parentCallback() {
        this.props.parentCallback("index");
    }

    toggleModal(msg, action) {
        let active = !this.state.modal;
        this.setState({ modal: active, modalMsg: msg, modalAction: action });
    }

    confirmModal() {
        if (this.state.modalAction === "delete") {
            this.deleteEmail();
        }
    }

    pullData() {
        fetch(`${Session.GLOBAL.serverURL}/project/email/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else if (results.length === 0) {
                    this.setState({
                        errorMsg: "Cannot pull email information",
                    });
                    console.warn("error");
                } else {
                    let data = results[0];
                    this.setState({
                        email_active: data.active,
                        email_name: data.name,
                        email_subject: data.email_subject,
                        send_time: dateFormat(
                            data.send_time,
                            "yyyy-mm-dd HH:MM"
                        ),
                        segment: data.segment,
                        html_email: data.html_email,
                        email_sent: data.sent,
                    });

                    if (data.sent === 1) {
                        this.setState({
                            sendTestMsg:
                                "Email sent on " +
                                dateFormat(
                                    data.sent_time,
                                    "mmmm d, yyyy h:mm tt"
                                ),
                        });
                    }

                    this.validateTime(data.send_time);
                }
            });
    }

    getSegments() {
        fetch(`${Session.GLOBAL.serverURL}/project/segments/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                for (const [index, value] of results.entries()) {
                    this.segment_list.push(
                        <option key={index} value={value.id}>
                            {value.name}
                        </option>
                    );
                }

                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                }

                this.forceUpdate();
            });
    }

    validateTime(date) {
        this.setState(
            {
                errorMsg: "",
                send_time_valid: true,
            },
            function () {
                this.validateEmail();
            }
        );

        if (this.state.email_sent === 0) {
            if (
                dateFormat(date, "yyyy-mm-dd") ===
                dateFormat(new Date(), "yyyy-mm-dd")
            ) {
                if (
                    dateFormat(date, "HH:MM:ss") <
                    dateFormat(new Date(), "HH:MM:ss")
                ) {
                    this.setState(
                        {
                            errorMsg: "Date selected has already passed",
                            send_time_valid: false,
                        },
                        function () {
                            this.validateEmail();
                        }
                    );
                }
            } else if (
                dateFormat(date, "yyyymmdd") <
                dateFormat(new Date(), "yyyymmdd")
            ) {
                this.setState(
                    {
                        errorMsg: "Date selected has already passed",
                        send_time_valid: false,
                    },
                    function () {
                        this.validateEmail();
                    }
                );
            }
        }
    }

    getEmailVariables() {
        this.email_variables.push(
            <div className="email-variables" key="index">
                <p>
                    <strong>Available Email Variables</strong>
                </p>
                <ul>
                    <li>First Name: %fname%</li>
                    <li>Last Name: %lname%</li>
                    <li>Email: %email%</li>
                </ul>
            </div>
        );
    }

    render() {
        return (
            <div className="create-form">
                {this.state.id ? (
                    <h2>Edit Email</h2>
                ) : (
                    <h2>Create New Email</h2>
                )}
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}
                {this.state.sendTestMsg ? (
                    <p className="success-box">{this.state.sendTestMsg}</p>
                ) : (
                    ""
                )}
                <form>
                    <label htmlFor="email_active">
                        <span>Email Active: </span>
                        <input
                            onChange={this.handleCheckBox}
                            type="checkbox"
                            name="email_active"
                            checked={this.state.email_active}
                            disabled={this.state.email_sent}
                        />
                    </label>
                    <label htmlFor="email_name">
                        <span>Name: </span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="email_name"
                            value={this.state.email_name}
                            disabled={this.state.email_sent}
                        />
                    </label>
                    <label htmlFor="email_subject">
                        <span>Email Subject: </span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="email_subject"
                            value={this.state.email_subject}
                            disabled={this.state.email_sent}
                        />
                    </label>
                    <label htmlFor="send_time">
                        <span>Send Time:</span>
                        <DatePicker
                            onChange={this.handleDateChange}
                            type="text"
                            name="send_time"
                            autoComplete="off"
                            selected={Date.parse(this.state.send_time)}
                            showTimeSelect
                            timeFormat="hh:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            excludeOutOfBoundsTimes
                            minDate={new Date()}
                            placeholderText="Click to select a date"
                            className="date-picker"
                            disabled={this.state.email_sent}
                        />
                    </label>
                    <label htmlFor="segment">
                        <span>Segments:</span>
                        {this.segment_list.length > 0 ? (
                            <select
                                name="segment"
                                onChange={this.handleChangeInput}
                                value={this.state.segment}
                                disabled={this.state.email_sent}
                            >
                                <option></option>
                                {this.segment_list}
                            </select>
                        ) : (
                            "No Segments Created"
                        )}
                    </label>
                    <label htmlFor="html_email">
                        <span>HTML Email:</span>
                    </label>
                    {this.email_variables.length > 0 ? (
                        this.email_variables
                    ) : (
                        <p>Loading...</p>
                    )}
                    <div className="email-content">
                        <textarea
                            name="html_email"
                            value={this.state.html_email}
                            onChange={this.handleChangeInput}
                            disabled={this.state.email_sent}
                        ></textarea>

                        <div className="email-preview">
                            <div
                                className="viewer"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.html_email,
                                }}
                            ></div>
                        </div>
                    </div>
                    <input type="hidden" value={this.state.project} />

                    <div className="form-buttons">
                        <button
                            type="submit"
                            onClick={this.saveEmail}
                            disabled={!this.state.validEmail}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="preview"
                            onClick={this.previewEmail}
                        >
                            Preview
                        </button>
                        <button
                            type="button"
                            className="send-test"
                            onClick={this.sendTest}
                        >
                            Send Test
                        </button>
                        {this.state.id && (
                            <button
                                type="button"
                                className="delete"
                                onClick={() => {
                                    this.toggleModal(
                                        "Are you sure you want to delete this email?",
                                        "delete"
                                    );
                                }}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </form>

                {this.state.modal && (
                    <Modal
                        msg={this.state.modalMsg}
                        toggle={this.toggleModal}
                        confirm={this.confirmModal}
                    />
                )}
            </div>
        );
    }
}

class EmailReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userToken: Session.getSession(),
            id: this.props.state.id,
            projectID: this.props.projectID,
            page_name: this.props.pageName,
            active: false,
            modal: false,
            modalMsg: false,
            modalAction: "",
        };

        this.emailDetails = {
            title: "",
            subject: "",
            sentTime: "",
            email: "",
            totalSends: 0,
            totalOpens: 0,
            totalClicks: 0,
            uniqueOpens: 0,
            uniqueClicks: 0,
            openRate: 0,
            clickRate: 0,
        };
        this.emailStats = [];

        this.emailPreview = "";

        this.toggleModal = this.toggleModal.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.parentCallback = this.parentCallback.bind(this);

        this.getEmailStats();
    }

    getEmailStats() {
        fetch(`${Session.GLOBAL.serverURL}/project/reports/email`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                for (const [index, value] of results.entries()) {
                    this.emailStats.push(
                        <div key={index} className="row column-4">
                            <span>
                                {value.first_name} {value.last_name}
                            </span>
                            <span>{value.email}</span>
                            <span>{value.opens}</span>
                            <span>{value.clicks}</span>
                        </div>
                    );

                    this.emailDetails.title = value.name;
                    this.emailDetails.subject = value.email_subject;
                    this.emailDetails.sentTime = value.sent_time;
                    this.emailDetails.email = value.html_email;
                    this.emailDetails.totalOpens =
                        this.emailDetails.totalOpens + value.opens;
                    this.emailDetails.totalClicks =
                        this.emailDetails.totalClicks + value.clicks;
                    this.emailDetails.totalSends =
                        this.emailDetails.totalSends + 1;

                    if (value.opens > 0) {
                        this.emailDetails.uniqueOpens =
                            this.emailDetails.uniqueOpens + 1;
                    }

                    if (value.clicks > 0) {
                        this.emailDetails.uniqueClicks =
                            this.emailDetails.uniqueClicks + 1;
                    }
                }

                this.emailDetails.openRate =
                    (this.emailDetails.uniqueOpens /
                        this.emailDetails.totalSends) *
                    100;
                this.emailDetails.clickRate =
                    (this.emailDetails.uniqueClicks /
                        this.emailDetails.totalSends) *
                    100;

                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                }

                this.forceUpdate();
            });
    }

    duplicateEmail() {
        fetch(`${Session.GLOBAL.serverURL}/project/email/duplicate`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg, modal: false });
                    console.warn("denied");
                } else {
                    this.parentCallback();
                }
            });
    }

    parentCallback() {
        this.props.parentCallback("index");
    }

    toggleModal(msg, action) {
        let active = !this.state.modal;
        this.setState({ modal: active, modalMsg: msg, modalAction: action });
    }

    confirmModal() {
        console.log(this.state.modalAction);
        if (this.state.modalAction === "duplicate") {
            this.duplicateEmail();
        }
    }

    render() {
        return (
            <div className="email-report">
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}

                {this.emailStats.length > 0 ? (
                    <div className="stats">
                        <div className="stats-header">
                            <h2>{this.emailDetails.title}</h2>

                            <div className="stat-information">
                                <p>
                                    <strong>Subject:</strong>{" "}
                                    {this.emailDetails.subject}
                                </p>
                                <p>
                                    <strong>Delivered:</strong>{" "}
                                    {dateFormat(
                                        this.emailDetails.sentTime,
                                        "mmm dd yyyy hh:MM:ss tt"
                                    )}
                                </p>
                            </div>

                            <div className="stat-table">
                                <p>
                                    {this.emailDetails.totalSends}
                                    <strong>Sent</strong>{" "}
                                </p>
                                <p>
                                    {this.emailDetails.totalOpens} (
                                    {this.emailDetails.uniqueOpens})
                                    <strong>Opens</strong>{" "}
                                </p>
                                <p>
                                    {this.emailDetails.totalClicks} (
                                    {this.emailDetails.uniqueClicks})
                                    <strong>Clicks</strong>{" "}
                                </p>
                                <p>
                                    {this.emailDetails.openRate.toFixed(2)}%
                                    <strong>Open Rate</strong>{" "}
                                </p>
                                <p>
                                    {this.emailDetails.clickRate.toFixed(2)}%
                                    <strong>Click Rate</strong>{" "}
                                </p>
                            </div>
                        </div>

                        <h2>Recipients</h2>

                        <div className="stat-results">
                            <div className="row column-4">
                                <span>
                                    <strong>Name</strong>
                                </span>
                                <span>
                                    <strong>Email</strong>
                                </span>
                                <span>
                                    <strong>Opens</strong>
                                </span>
                                <span>
                                    <strong>Clicks</strong>
                                </span>
                            </div>

                            {this.emailStats}
                        </div>
                    </div>
                ) : (
                    "No Email Stats"
                )}

                <div className="email-preview">
                    <h2>Email Preview</h2>
                    <div
                        className="viewer"
                        dangerouslySetInnerHTML={{
                            __html: this.emailDetails.email,
                        }}
                    ></div>

                    <div className="form-buttons">
                        <button
                            type="button"
                            className="duplicate-email"
                            onClick={() => {
                                this.toggleModal(
                                    "Are you sure you want to duplicate this email?",
                                    "duplicate"
                                );
                            }}
                        >
                            Duplicate Email
                        </button>
                    </div>
                </div>

                {this.state.modal && (
                    <Modal
                        msg={this.state.modalMsg}
                        toggle={this.toggleModal}
                        confirm={this.confirmModal}
                    />
                )}
            </div>
        );
    }
}

export default App;
