import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import PrimaryNav from "../Components/PrimaryNav";
import Nav from "../Components/Nav";

import Index from "../Components/Index";
import Dashboard from "../Components/Dashboard";
import Emails from "../Components/Emails";
import Projects from "../Components/Projects";
import Account from "../Components/Account";
import Contacts from "../Components/Contacts";
import Segments from "../Components/Segments";
import Error from "../Components/404";

import Session from "../Session";
let loggedIn = Session.checkSession();
var updateStatus = 0;

class App extends Component {
    constructor() {
        super();
        this.update = this.update.bind(this);
        this.renderPrimaryNav();
    }

    renderPrimaryNav() {
        ReactDOM.render(
            <PrimaryNav loggedIn={loggedIn} update={updateStatus++} />,
            document.getElementById("primary-nav")
        );
    }

    renderSecondaryNav() {
        ReactDOM.render(
            <Nav loggedIn={loggedIn} />,
            document.getElementById("secondary-nav")
        );
    }

    update() {
        this.renderPrimaryNav();
    }

    render() {
        //BODY CLASSES
        if (loggedIn) {
            document.body.classList.add("reg-user");
        } else {
            document.body.classList.add("guest-user");
        }

        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Index} />
                    <Route
                        path="/projects"
                        render={() => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Projects update={this.update} />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route
                        path="/project/:hash/emails"
                        render={props => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Emails
                                    page={props}
                                    renderSecondaryNav={this.renderSecondaryNav}
                                />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route
                        path="/project/:hash/contacts"
                        render={props => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Contacts
                                    page={props}
                                    renderSecondaryNav={this.renderSecondaryNav}
                                />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route
                        path="/project/:hash/segments"
                        render={props => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Segments
                                    page={props}
                                    renderSecondaryNav={this.renderSecondaryNav}
                                />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route
                        path="/project/:hash"
                        render={props => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Dashboard
                                    page={props}
                                    renderSecondaryNav={this.renderSecondaryNav}
                                />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route
                        path="/account"
                        render={() => {
                            return loggedIn && loggedIn.length > 0 ? (
                                <Account />
                            ) : (
                                <Redirect to="/" />
                            );
                        }}
                    />
                    <Route component={Error} />
                </Switch>
            </Router>
        );
    }
}

export default App;
