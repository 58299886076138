import React, { Component } from "react";
import CSVReader from "react-csv-reader";
import dateFormat from "dateformat";

import Session from "../Session";
import Modal from "./Modal";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: "index",
            pageId: props.page.match.params.hash,
            pageName: "",
            projectID: this.props.page.match.params.hash,
            id: null,
            validUser: false,
        };

        this.changeState = this.changeState.bind(this);
        this.props.renderSecondaryNav();
        this.checkAuth(Session.getSession(), this.state.pageId);
    }

    checkAuth(userToken, pageId) {
        fetch(`${Session.GLOBAL.serverURL}/projects/campaign/auth`, {
            method: "POST",
            body: JSON.stringify({ userToken, pageId }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    this.setState({
                        validUser: true,
                        pageName: results[0].name,
                    });
                } else {
                    console.warn("Invalid user");
                    window.location.href = "/404";
                }
            });
    }

    changeState(value, id = null) {
        this.setState({ page: value, id: id });
    }

    render() {
        return (
            <div className="projects list">
                <div className="top">
                    <h1>{this.state.pageName} Contacts</h1>
                    {this.state.page === "index" ? (
                        <div>
                            <button
                                className="link"
                                onClick={() => this.changeState("create")}
                            >
                                Create A Contact
                            </button>

                            <button
                                className="link"
                                onClick={() => this.changeState("import")}
                            >
                                Import Contacts
                            </button>
                        </div>
                    ) : (
                        <button
                            className="link"
                            onClick={() => this.changeState("index")}
                        >
                            Back To Contacts
                        </button>
                    )}
                </div>

                {(() => {
                    switch (this.state.page) {
                        case "create":
                            return (
                                <ContactCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        case "edit":
                            return (
                                <ContactCreate
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        case "import":
                            return (
                                <ContactImport
                                    parentCallback={this.changeState}
                                    state={this.state}
                                    projectID={this.state.projectID}
                                />
                            );
                        default:
                            return (
                                <ContactList
                                    parentCallback={this.changeState}
                                    projectID={this.state.projectID}
                                />
                            );
                    }
                })()}
            </div>
        );
    }
}

class ContactList extends Component {
    constructor(props) {
        super(props);
        this.items = [];
        this.segmentList = [];
        this.selectAllToggle = false;

        this.state = {
            userToken: Session.getSession(),
            projectID: this.props.projectID,
            editList: [],
            errorMsg: "",
        };

        this.parentCallback = this.parentCallback.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.changeSegment = this.changeSegment.bind(this);
        this.selectAll = this.selectAll.bind(this);

        this.getContacts();
    }

    parentCallback(id) {
        this.props.parentCallback("edit", id);
    }

    handleCheckBox = (event) => {
        let value = event.target.value;
        let checked = event.target.checked;
        let array = [];

        if (checked) {
            //ADD CHECKED
            for (var i = 0; i < this.state.editList.length; i++) {
                array.push(this.state.editList[i]);
            }

            array.push(value);
        } else {
            array = this.state.editList;
            //REMOVE UNCHECKED
            for (var x = 0; x < array.length; x++) {
                if (array[x] === value) {
                    array.splice(x, 1);
                }
            }
        }

        this.setState({ editList: array.sort() });
    };

    changeSegment = (event, status) => {
        this.setState({ errorMsg: "" });

        if (this.state.editList.length === 0) {
            this.setState({ errorMsg: "No segments selected" });
        } else {
            this.setState(
                { segmentEditID: event.target.value, segmentStatus: status },
                this.updateSegment
            );
        }

        //CLEAN CHECKBOXES
        document.getElementById("add-segments").selectedIndex = 0;
        document.getElementById("remove-segments").selectedIndex = 0;
        document.getElementById("select-all").checked = false;
        var checkboxes = document.querySelectorAll(
            ".project-list ul input[type='checkbox']:checked"
        );

        for (var index in checkboxes) {
            if (checkboxes[index].checked) {
                checkboxes[index].checked = false;
            }
        }
    };

    updateSegment = () => {
        var path = "";

        if (this.state.segmentStatus === "add") {
            path = `${Session.GLOBAL.serverURL}/project/contact/segment/add`;
        } else {
            path = `${Session.GLOBAL.serverURL}/project/contact/segment/remove`;
        }

        fetch(path, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error === false) {
                    this.setState({
                        successMsg: "Segment updated successfully",
                        editList: [],
                    });

                    this.getContacts();
                }
            });
    };

    getContacts() {
        const userToken = this.state.userToken;
        const projectID = this.state.projectID;
        this.items = [];

        fetch(`${Session.GLOBAL.serverURL}/project/contacts/get`, {
            method: "POST",
            body: JSON.stringify({ userToken, projectID }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    this.getSegments().then((segments) => {
                        var rowSegments = "";
                        var segmentRaw = {};

                        //BUILD SEGMENT NAMES
                        for (const value of segments.entries()) {
                            segmentRaw[value[1].id] = value[1].name;
                        }

                        //LOOP RESULTS
                        for (const [index, value] of results.entries()) {
                            rowSegments = [];

                            if (value.segments) {
                                var parseSegments = JSON.parse(value.segments);

                                //LOOP SEGMENT ROW IDS
                                for (var x = 0; x < parseSegments.length; x++) {
                                    rowSegments.push(
                                        segmentRaw[parseSegments[x]]
                                    );
                                }
                            }

                            //STRINGIFY SEGMENTS
                            rowSegments = rowSegments
                                .sort()
                                .toString()
                                .replace(",", ", ");

                            this.items.push(
                                <div key={index}>
                                    <span>
                                        <input
                                            type="checkbox"
                                            onChange={this.handleCheckBox}
                                            value={value.id}
                                        />
                                    </span>

                                    <li
                                        id={value.id}
                                        onClick={() =>
                                            this.parentCallback(value.id)
                                        }
                                    >
                                        <span>
                                            {value.first_name} {value.last_name}
                                        </span>
                                        <span>{value.email}</span>
                                        <span>{rowSegments}</span>
                                        <span>
                                            {dateFormat(
                                                value.updated_at,
                                                "mmm dd yyyy hh:MM:ss tt"
                                            )}
                                        </span>
                                    </li>
                                </div>
                            );
                        } //END FOR LOOP
                        this.forceUpdate();
                    });
                } else {
                    this.items.push("No contacts created");
                    this.forceUpdate();
                }
            });
    }

    getSegments() {
        const userToken = this.state.userToken;
        const projectID = this.state.projectID;

        return fetch(`${Session.GLOBAL.serverURL}/project/segments/get`, {
            method: "POST",
            body: JSON.stringify({ user_token: userToken, project: projectID }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.length > 0) {
                    for (const [index, value] of results.entries()) {
                        this.segmentList.push(
                            <option key={"select" + index} value={value.id}>
                                {value.name}
                            </option>
                        );
                    }
                } else {
                    this.segmentList.push("No contact created");
                }

                return results;
            });
    }

    selectAll() {
        let array = [];
        var checkboxes = document.querySelectorAll(
            ".project-list ul input[type='checkbox']"
        );

        //TOGGLE
        for (const index of checkboxes) {
            array.push(index.value);
            index.checked = !this.selectAllToggle;
        }

        this.selectAllToggle = !this.selectAllToggle;
        this.setState({ editList: array.sort() });
    }

    render() {
        let message;

        if (this.state.errorMsg) {
            message = <p className="error-box">{this.state.errorMsg}</p>;
        } else if (this.state.successMsg) {
            message = <p className="success-box">{this.state.successMsg}</p>;
        } else {
            message = "";
        }

        return (
            <div className="project-list">
                {message}
                <ul>{this.items.length > 0 ? this.items : "Loading..."}</ul>
                {this.segmentList.length > 0 ? (
                    <div className="bottom-actions">
                        <label>
                            <input
                                type="checkbox"
                                id="select-all"
                                onClick={this.selectAll}
                            />
                            {this.selectAllToggle
                                ? "Deselect All"
                                : "Select All"}
                        </label>
                        <div className="segments">
                            <select
                                name="segment"
                                id="add-segments"
                                onChange={(e) => this.changeSegment(e, "add")}
                            >
                                <option value="">Add Segment</option>
                                {this.segmentList}
                            </select>
                            <select
                                name="segment"
                                id="remove-segments"
                                onChange={this.changeSegment}
                            >
                                <option value="">Remove Segment</option>
                                {this.segmentList}
                            </select>{" "}
                        </div>
                    </div>
                ) : (
                    "No segments"
                )}
            </div>
        );
    }
}

class ContactImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: Session.getSession(),
            projectID: this.props.projectID,
            errorMsg: "",
            fields: {},
            values: {},
        };

        this.previewResults = [];
        this.parentCallback = this.parentCallback.bind(this);
        this.handeOptions = this.handeOptions.bind(this);
        this.uploadResults = this.uploadResults.bind(this);
    }

    handleUpload = (event) => {
        this.setState({ errorMsg: "", values: event });

        for (const [index, row] of event.entries()) {
            this.previewResults.push(
                <tr key={index}>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.email}</td>
                </tr>
            );
        }
        this.forceUpdate();
    };

    handeOptions = (event) => {
        this.setState({ errorMsg: "" });
        var id = event.target.id;
        var field = event.target.value;
        var tmpFields = this.state.fields;
        var checkDuplicates = [];

        tmpFields[id] = field;
        this.setState({ fields: tmpFields });

        //NO BLANK VALUES ALLOWED
        if (field.length === 0) {
            this.setState({
                errorMsg: "Field cannot be blank",
            });
        }

        //CHECK DUPLICATES
        for (var value in tmpFields) {
            if (checkDuplicates.indexOf(tmpFields[value]) === -1) {
                checkDuplicates.push(tmpFields[value]);
            } else {
                this.setState({
                    errorMsg: "Duplicate fields selected",
                });
            }
        }
    };

    uploadResults() {
        var tmpValues = [];
        const values = this.state.values;

        for (const index in values) {
            var i = 0;
            var tmpField = {};

            for (var key of Object.keys(values[index])) {
                var field = this.state.fields[
                    Object.keys(this.state.fields)[i]
                ];

                tmpField[field] = values[index][key];
                i++;
            }

            tmpValues.push(tmpField);
        }

        this.setState({ values: tmpValues }, function () {
            fetch(`${Session.GLOBAL.serverURL}/project/contact/import`, {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((results) => {
                    if (results.error === false) {
                        this.parentCallback();
                    } else {
                        this.setState({ errorMsg: "Error uploading file" });
                    }
                });
        });
    }

    parentCallback() {
        this.props.parentCallback("index");
    }

    handleError = (event) => {
        this.setState({ errorMsg: "Error uploading file" });
    };

    render() {
        return (
            <div className="create-form">
                <h2>Import Contacts</h2>
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}

                {this.previewResults.length > 0 ? (
                    <div className="upload-preview">
                        <p>
                            <strong>
                                Map your contact fields to uploaded values.
                            </strong>
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        <select
                                            id="1"
                                            onChange={this.handeOptions}
                                        >
                                            <option></option>
                                            <option value="first_name">
                                                First Name
                                            </option>
                                            <option value="last_name">
                                                Last Name
                                            </option>
                                            <option value="email">Email</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            id="2"
                                            onChange={this.handeOptions}
                                        >
                                            <option></option>
                                            <option value="first_name">
                                                First Name
                                            </option>
                                            <option value="last_name">
                                                Last Name
                                            </option>
                                            <option value="email">Email</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            id="3"
                                            onChange={this.handeOptions}
                                        >
                                            <option></option>
                                            <option value="first_name">
                                                First Name
                                            </option>
                                            <option value="last_name">
                                                Last Name
                                            </option>
                                            <option value="email">Email</option>
                                        </select>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>{this.previewResults}</tbody>
                        </table>

                        <button
                            onClick={this.uploadResults}
                            disabled={
                                this.state.errorMsg ||
                                Object.keys(this.state.fields).length < 3
                            }
                        >
                            Continue and Upload {this.state.validUpload}
                        </button>
                    </div>
                ) : (
                    <CSVReader
                        cssClass="contact-upload"
                        label="Upload CSV Contact(s) File"
                        onFileLoaded={this.handleUpload}
                        onError={this.handleDarkSideForce}
                        parserOptions={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) =>
                                header.toLowerCase().replace(/\W/g, "_"),
                        }}
                        inputId="fileUpload"
                    />
                )}
            </div>
        );
    }
}

class ContactCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: "",
            lname: "",
            email: "",
            segments: [],
            user_token: Session.getSession(),
            validContact: false,
            errorMsg: "",
            id: this.props.state.id,
            project: this.props.projectID,
            modal: false,
        };

        this.segmentList = [];
        this.emailStats = [];

        this.validateContact = this.validateContact.bind(this);
        this.saveContact = this.saveContact.bind(this);
        this.parentCallback = this.parentCallback.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.getSegments = this.getSegments.bind(this);

        if (this.props.state.id) {
            this.pullData();
        } else {
            this.getSegments();
        }
    }

    validateContact() {
        if (
            this.state.fname.length > 0 &&
            this.state.lname.length > 0 &&
            this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
            this.state.email.length > 0
        ) {
            this.setState({ validContact: true });
        } else {
            this.setState({ validContact: false });
        }
    }

    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value }, function () {
            this.validateContact();
        });
    };

    handleCheckBox = (event) => {
        var value = event.target.value;
        var currentSegments = this.state.segments;

        if (event.target.checked) {
            currentSegments.push(value);
        } else {
            var index = currentSegments.indexOf(value);
            if (index !== -1) currentSegments.splice(index, 1);
        }

        this.setState({
            segments: currentSegments,
        });
    };

    getSegments() {
        fetch(`${Session.GLOBAL.serverURL}/project/segments/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                var checked = "";

                for (const [index, value] of results.entries()) {
                    checked = false;
                    if (this.state.segments.indexOf(value.id.toString()) > -1) {
                        checked = true;
                    }

                    this.segmentList.push(
                        <div key={index}>
                            <label>
                                <span>{value.name}</span>
                                <input
                                    type="checkbox"
                                    value={value.id}
                                    defaultChecked={checked}
                                    onClick={this.handleCheckBox}
                                />
                            </label>
                        </div>
                    );
                }

                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                }

                this.forceUpdate();
            });
    }

    saveContact(e) {
        e.preventDefault();
        this.setState({ errorMsg: "" });

        if (this.state.validContact) {
            fetch(`${Session.GLOBAL.serverURL}/project/contact/save`, {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((results) => {
                    if (results.error) {
                        this.setState({ errorMsg: results.msg });
                        console.warn("denied");
                    } else {
                        this.parentCallback();
                    }
                });
        }
    }

    deleteContact() {
        fetch(`${Session.GLOBAL.serverURL}/project/contact/delete`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else {
                    this.parentCallback();
                }
            });
    }

    parentCallback() {
        this.props.parentCallback("index");
    }

    toggleModal() {
        let active = !this.state.modal;
        this.setState({ modal: active });
    }

    confirmModal() {
        this.deleteContact();
    }

    pullData() {
        fetch(`${Session.GLOBAL.serverURL}/project/contact/get`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                if (results.error) {
                    this.setState({ errorMsg: results.msg });
                    console.warn("denied");
                } else if (results.length === 0) {
                    this.setState({
                        errorMsg: "Cannot pull contact information",
                    });
                    console.warn("error");
                } else {
                    let data = results[0];
                    this.setState({
                        fname: data.first_name,
                        lname: data.last_name,
                        email: data.email,
                        segments: data.segments
                            ? JSON.parse(data.segments)
                            : [],
                    });

                    this.validateContact();
                    this.getSegments();
                    this.getEmailStats();
                }
            });
    }

    getEmailStats() {
        fetch(`${Session.GLOBAL.serverURL}/project/reports/contact`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((results) => {
                for (const [index, value] of results.entries()) {
                    console.log(value);
                    this.emailStats.push(
                        <div key={index} className="row column-5">
                            <span>{value.name}</span>
                            <span>{value.email_subject}</span>
                            <span>
                                {dateFormat(
                                    value.sent_time,
                                    "mmm dd yyyy hh:MM:ss tt"
                                )}
                            </span>
                            <span>{value.opens}</span>
                            <span>{value.clicks}</span>
                        </div>
                    );
                }

                this.forceUpdate();
            });
    }

    render() {
        return (
            <div className="create-form">
                {this.state.id ? (
                    <h2>Edit Contact</h2>
                ) : (
                    <h2>Create New Contact</h2>
                )}
                {this.state.errorMsg ? (
                    <p className="error-box">{this.state.errorMsg}</p>
                ) : (
                    ""
                )}
                <form>
                    <label htmlFor="fname">
                        <span>First Name: </span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="fname"
                            value={this.state.fname}
                        />
                    </label>
                    <label htmlFor="lname">
                        <span>Last Name: </span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="lname"
                            value={this.state.lname}
                        />
                    </label>
                    <label htmlFor="email">
                        <span>Email:</span>
                        <input
                            onChange={this.handleChangeInput}
                            type="text"
                            name="email"
                            value={this.state.email}
                        />
                    </label>
                    <fieldset>
                        <legend>Segments:</legend>
                        {this.segmentList.length > 0
                            ? this.segmentList
                            : "No Segments Created"}
                    </fieldset>

                    <input type="hidden" value={this.state.project} />
                    <div className="form-buttons">
                        <button
                            type="submit"
                            onClick={this.saveContact}
                            disabled={!this.state.validContact}
                        >
                            Save
                        </button>
                        {this.state.id && (
                            <button
                                type="button"
                                className="delete"
                                onClick={this.toggleModal}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </form>

                {this.emailStats.length > 0 ? (
                    <div className="email-report">
                        <h2>Email Activity</h2>

                        <div className="stat-results">
                            <div className="row column-5">
                                <span>
                                    <strong>Title</strong>
                                </span>
                                <span>
                                    <strong>Subject</strong>
                                </span>
                                <span>
                                    <strong>Delivered</strong>
                                </span>
                                <span>
                                    <strong>Opens</strong>
                                </span>
                                <span>
                                    <strong>Clicks</strong>
                                </span>
                            </div>

                            {this.emailStats}
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {this.state.modal && (
                    <Modal
                        msg="Are you sure you want to delete this contact?"
                        toggle={this.toggleModal}
                        confirm={this.confirmModal}
                    />
                )}
            </div>
        );
    }
}

export default App;
