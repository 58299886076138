import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./Components/styles/_global.scss";

import Content from "./Components/Router";
import Version from "./Components/Version";

ReactDOM.render(<Content />, document.getElementById("content"));
ReactDOM.render(<Version />, document.getElementById("version"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
