import React, { Component } from "react";
import Session from "../Session";

import "./styles/_signIn.scss";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            validEmail: false,
            errorEmail: false,
            validPassword: false,
            errorPassword: false,
            errorSubmit: false,
            overlay: true
        };

        this.errorMsg = "Your email and/or password is invalid";
        this.signIn = this.signIn.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    handleChange = event => {
        this.setState({ trigger: true });

        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.name === "email") {
            this.setState({
                validEmail: this.validateEmail(event.target.value),
                errorEmail: !this.validateEmail(event.target.value)
            });
        }

        if (event.target.name === "password") {
            this.setState({
                validPassword:
                    event.target.value.length >= Session.GLOBAL.passwordLength,
                errorPassword:
                    event.target.value.length < Session.GLOBAL.passwordLength
            });
        }
    };

    validateEmail(email) {
        const emailValid = !!email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        return emailValid;
    }

    signIn(e) {
        e.preventDefault();
        this.setState({ errorSubmit: false });

        fetch(`${Session.GLOBAL.serverURL}/login`, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(results => {
                if (results.length > 0) {
                    Session.startSession(
                        results[0].name,
                        results[0].email,
                        results[0].token
                    );
                    window.location.href = "/projects";
                } else {
                    this.setState({ errorSubmit: true });
                    console.warn("denied");
                }
            });
    }

    closeOverlay() {
        this.props.parentCallback(false);
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-form">
                    <header>
                        <h1>Sign In</h1>
                        <span className="close" onClick={this.closeOverlay}>
                            X
                        </span>
                    </header>

                    {this.state.errorSubmit && (
                        <p className="error-box">{this.errorMsg}</p>
                    )}
                    <form>
                        <label htmlFor="email">
                            Email{" "}
                            {this.state.errorEmail && (
                                <span className="error">(not valid)</span>
                            )}
                        </label>
                        <input
                            type="email"
                            placeholder="email"
                            name="email"
                            onChange={this.handleChange}
                        />
                        <label htmlFor="password">
                            Password{" "}
                            {this.state.errorPassword && (
                                <span className="error">(not valid)</span>
                            )}
                        </label>
                        <input
                            type="password"
                            placeholder="password"
                            name="password"
                            onChange={this.handleChange}
                        />

                        <button
                            type="submit"
                            onClick={this.signIn}
                            disabled={
                                !this.state.validEmail ||
                                !this.state.validPassword
                            }
                        >
                            Sign In
                        </button>
                    </form>
                </div>
                <div
                    className={
                        "overlay " + (this.state.overlay ? "show" : "hide")
                    }
                    onClick={this.closeOverlay}
                ></div>
            </div>
        );
    }
}

export default App;
