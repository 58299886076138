import React from "react";
import pkg from "../../package.json";

function App() {
    return (
        <div className="bottom">
            <p>v{pkg.version}</p>
        </div>
    );
}

export default App;
