import React, { Component } from "react";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: true,
            title: this.props.msg
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.props.toggle();
    }

    render() {
        if (this.state.active) {
            return (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            {this.state.title}
                            <span onClick={this.toggleModal}>X</span>
                        </div>
                        <button
                            type="button"
                            className="yes"
                            onClick={() => this.props.confirm()}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="no"
                            onClick={this.toggleModal}
                        >
                            No
                        </button>
                    </div>
                    <div
                        className="overlay show"
                        onClick={this.toggleModal}
                    ></div>
                </div>
            );
        }
    }
}

export default App;
