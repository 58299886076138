import React, { Component } from "react";
var pathName =
    "/" +
    window.location.pathname.split("/")[1] +
    "/" +
    window.location.pathname.split("/")[2];

class App extends Component {
    render() {
        return (
            <div>
                {this.props.loggedIn && (
                    <ul>
                        <li>
                            <a href={pathName}>Dashboard</a>
                        </li>
                        <li>
                            <a href={pathName + "/emails"}>Emails</a>
                        </li>
                        <li>
                            <a href={pathName + "/contacts"}>Contacts</a>
                        </li>
                        <li>
                            <a href={pathName + "/segments"}>Segments</a>
                        </li>
                    </ul>
                )}
            </div>
        );
    }
}

export default App;
