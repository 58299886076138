import React, { Component } from "react";
import Session from "../Session";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageId: props.page.match.params.hash,
            validUser: false,
            name: "",
            color: "",
            date: "",
        };

        this.checkAuth(Session.getSession(), this.state.pageId);
        this.parentCallback = this.parentCallback.bind(this);
    }

    parentCallback() {
        this.props.renderSecondaryNav();
    }

    checkAuth(userToken, pageId) {
        fetch(`${Session.GLOBAL.serverURL}/projects/campaign/auth`, {
            method: "POST",
            body: JSON.stringify({ userToken, pageId }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json(this.token))
            .then((results) => {
                if (results.length > 0) {
                    this.setState({
                        validUser: true,
                        name: results[0].name,
                        color: results[0].color,
                        date: results[0].updated_at,
                    });
                    this.parentCallback();
                } else {
                    console.warn("Invalid user");
                    window.location.href = "/404";
                }
            });
    }

    render() {
        return (
            <div className="projects">
                <div className="top">
                    {this.state.validUser ? (
                        <h1>{this.state.name} Dashboard</h1>
                    ) : (
                        "Loading..."
                    )}
                </div>
            </div>
        );
    }
}

export default App;
