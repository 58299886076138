import React from "react";

function App() {
    return (
        <div className="index">
            <h1>My Account</h1>
        </div>
    );
}

export default App;
